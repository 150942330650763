window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    document.getElementById("header").style.height = "100px";
    document.getElementById("logo").style.fontSize = "1.4rem";
    document.getElementById("logo-sub").style.fontSize = "1rem";
  } else {
    document.getElementById("header").style.height = "160px";
    document.getElementById("logo").style.fontSize = "2rem";
    document.getElementById("logo-sub").style.fontSize = "1.25rem";

  }
}